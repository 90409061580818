<template>
        <section class="LightGrey" style="position:relative;">
            <div class="Container">
                <div class="Box">
                    <div class="DataMainHeader">
                        <div class="Index">
                            <h2>Assistance</h2>
                        </div>
                        <div class="AddContainer">
                            <div class="AddPanelOpenButton"><span>Add new</span><button @click="ShowNewPanel()">
                                <img src="/assets/images/AddWhiteIcon.png" srcset="/assets/images/AddWhiteIconRetina.png 2x" alt="" /></button></div>
                            <div  v-bind:class="{ addform:NewRequest }">
                            <div class="AddPanel">
                                <div class="AddPanelForm Assistance" v-if="NewRequest">
                                    <div class="AddPanelCloseButton"><button @click="ShowNewPanel()"><img
                                                src="/assets/images/CloseWhiteIcon.png"
                                                srcset="/assets/images/CloseWhiteIconRetina.png 2x" alt="" /></button>
                                    </div>
                                    <form class="Form">
                                        <fieldset>
                                            <legend>Assistance request</legend>
                                            <div class="FormRow">
                                                <div class="FormBoxWide">
                                                    <label>Customer</label>
                                                    <label>Select your customer from the list. Leave it blank if you need assistance.</label>
                                                      <v-select 
                                                      v-model="Customer"
                                                      label="RagioneSociale"
                                                      class="custom-select"
                                                      :value="Customers.id"
                                                      :options="Customers"
                                                      ></v-select>
                                                </div>
                                            </div>

                                            <div class="FormRow">
                                                <div class="FormBoxWide">
                                                    <label>Subject</label>
                                                    <input type="text" id="" name="" placeholder="Your placeholder">
                                                </div>
                                            </div>
                                            <div class="FormRow">
                                                <div class="FormBoxWide">
                                                    <label>Message</label>
                                                    <textarea placeholder="Write a text"></textarea>
                                                </div>
                                            </div>
                                            <div class="FormRow">
                                                <div class="FormBoxWide">
                                                    <div class="UploadLabel">Add file/document ( pdf/png/jpg )</div>
                                                    <VueFileAgent
                                                        ref="vueFileAgent"
                                                        :theme="'list'"
                                                        :multiple="true"
                                                        :deletable="true"
                                                        :meta="true"
                                                        :accept="'.jpg,.pdf,.png'"
                                                        :maxSize="'5MB'"
                                                        :maxFiles="5"
                                                        :helpText="'Drag or click to choose'"
                                                        :errorText="{
                                                        type: 'Invalid file type.',
                                                        size: 'Files should not exceed 5MB in size',
                                                        }"
                                                        @select="filesSelected($event)"
                                                        @beforedelete="onBeforeDelete($event)"
                                                        @delete="fileDeleted($event)"
                                                        v-model="fileRecords"
                                                    ></VueFileAgent>

                                                </div>
                                            </div>
                                            <div class="WhiteSpace">&nbsp;</div>
                                            <div class="FormRow">
                                                <div class="FormBoxWide">
                                                    <div class="TwinButtons">
                                                        <div class="Button">
                                                            <input type="button" id="" name="" value="Delete">
                                                        </div>
                                                        <div class="Button">
                                                            <input type="button" id="" name="" value="Confirm request">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="Box">
                    <div class="Binder DataMainTable">
                        <div class="Frame DataTabHolder">
                            <div class="DataMainTableHead DataTabButtonBox">
                                <div class="DataMainTableSplit">
                                    <a class="DataTabButton" @click="Switcher()" v-bind:class="[Switch === 'Recorded' ? 'SelectedItem' : '']"><span>Open Requests</span></a>
                                    <a class="DataTabButton" @click="Switcher()" v-bind:class="[Switch === 'Managed' ? 'SelectedItem' : '']"><span>Closed Requests</span></a>
                                </div>
                                <div class="SearchbarContainer">
                                    <div class="SearchBar">
                                        <form class="Form">
                                            <h5>Search</h5>
                                            <div class="FormRow">
                                                <div class="FormBox75">
                                                    <input type="text" id="" name="" placeholder="Search..." v-model="SearchText">
                                                </div>
                                                <div class="FormBox25">
                                                    <div class="Button">
                                                        <input type="submit" id="" name="" value="">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="DataMainTableContent DataTabContentBox">
                                <div class="DataTabContent">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                        class="AssistanceTable">
                                        <tbody>

                                            <tr>
                                                <th scope="col">{{$t('ASSISTANCE.TABLE.CODE')}}</th>
                                                <th scope="col">{{$t('ASSISTANCE.TABLE.DATE')}}</th>
                                                <th scope="col">{{$t('ASSISTANCE.TABLE.SUBJECT')}}</th>
                                                <th scope="col">{{$t('ASSISTANCE.TABLE.REQUESTED_BY')}}</th>
                                                <th scope="col">{{$t('ASSISTANCE.TABLE.PROJECT_NAME')}}</th>
                                                <th scope="col">{{$t('ASSISTANCE.TABLE.COMPANY_NAME')}}</th>
                                                <th scope="col">&nbsp;</th>
                                            </tr>

                                        </tbody>
                                            <assistance-row v-for="Request in filteredRequests" :key="Request.id" :Request="Request"></assistance-row>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
</template>

<script>
import AssistanceRow from '@/components/Assistance/Row.vue'
import AxiosClient from '../service/api.js'

export default {
  name: 'Assistance',
   data() {
        return {
            Switch: "Recorded",
            NewRequest: false,
            SearchText:"",
            Requests: [],
            Customers: [],
            Projects: [],
            Customer:[],
            Project:[],
            fileRecords:[],
            Subject:String,
            Messagge:String,
            File:String
        }
   },
     components: {
    'assistance-row': AssistanceRow,
    },
    methods: {
        Switcher() {
            if  ( this.Switch === "Recorded" ) {
                this.Switch = "Managed"
            } else {
                this.Switch = "Recorded"
            }
        },
        ShowNewPanel() {
            if  ( this.NewRequest === true ) {
               this.NewRequest = false
            } else {
                this.NewRequest = true
            }
        },
        getReportList() {
            AxiosClient
                .get('reports')
                .then(response => (
                    this.Requests = response.data.Data))
        },
        getCustomers() {
            AxiosClient
                .get('endusers')
                .then(response => (
                    this.Customers = response.data.Data))
                },
    },

    mounted() {
      this.getReportList()
      this.getCustomers()
    },
    computed: {

    filteredRequests() {
      return this.Requests.filter(item => {
         return item.Stato.toLowerCase().indexOf(this.Switch.toLowerCase()) > -1
      })
    }, 
    },
    watch: {
        Customer: function (oldvalue, newvalue){
               this.getProjects(newvalue) 
        }
  },
}
</script>
<style>
.custom-select .vs__search::placeholder,
.custom-select .vs__dropdown-toggle,
.custom-select .vs__dropdown-menu {
  background: #fff!important;
  border: none;
}

.custom-select input {
    height: 28px;
}
.custom-select .vs__clear,
.custom-select .vs__open-indicator {
  fill: #000;
}
button.vs__clear{
    min-width: auto!important;
    box-shadow: none!important;
}
</style>