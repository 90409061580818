<template>
         <section class="LightGrey">
            <div class="Container">
                <div class="Box">
                    <div class="Index"><h2>News from our network</h2></div>
                </div>
                <div class="Box">
                    <div class="Binder Bulletin">
                        <!-- Inizio repeater -->
                         <bulletin-card v-for="News in Newstand" :key="News.id" :Article="News" />
                        <!-- Fine repeater -->
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import BulletinCard from '../components/BullettinCard.vue'
import AxiosClient from '../service/api.js'

export default {
    data: function() {
    return {
        Newstand:[]
    };
    },
    metaInfo: () => ({
        title: 'Bullettin'}),
    created() {
    },
    components: { BulletinCard },
      methods: {
        getNews() {
            AxiosClient
                .get( 'news' )
                .then( response => (this.Newstand = response.data.Data ) )
        },
      },
    mounted() {
        this.getNews()
    },

};
</script>