<template>
  <div class="dashboard">
        <section class="LightGrey" style="position:relative;">
            <div class="Container">
                <div class="Box">
                    <div class="DataMainHeader">
                        <div class="Index"><h2>Accounts</h2></div>
                        <div class="AddContainer">
                            <div  class="AddPanelOpenButton"><span>Add new</span><button onClick="addform();"><img src="/assets/images/AddWhiteIcon.png" srcset="/assets/images/AddWhiteIconRetina.png 2x" alt=""/></button></div>    
                            <div class="AddPanel">
                                
                            </div>
                        </div>
                    </div>     
                </div>
                <div class="Box">
                    <div class="Binder DataMainTable">
                        <div class="Frame DataTabHolder">
                            <div class="DataMainTableHead DataTabButtonBox">
                                <div class="DataMainTableSplit">
                                    <a class="DataTabButton"><span>Clients</span></a>
                                    <!-- <a class="DataTabButton"><span>Prospects</span></a>
                                    <a class="DataTabButton"><span>Requests</span></a> -->
                                </div>
                                <div class="SearchbarContainer">
                                    <div class="SearchBar">
                                        <form class="Form">
                                            <h5>Search by code or topics</h5>
                                            <div class="FormRow">
                                                <div class="FormBox75">
                                                    <input type="text" id="" name="" placeholder="Search...">
                                                </div>
                                                <div class="FormBox25">
                                                    <div class="Button">
                                                        <input type="submit" id="" name="" value="">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="DataMainTableContent DataTabContentBox">
                                <div class="DataTabContent">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="ClientsTable">
    <tbody>
        <tr>
            <th scope="col">Company name</th>
            <th scope="col">Vat nr°</th>
            <th scope="col">Country</th>
            <th scope="col">City</th>
            <th scope="col">Agent</th>
            <th scope="col">&nbsp;</th>
        </tr>
        <!-- Inizio repeater -->
        <tr>
            <td><div class="TableLabel">Company name</div>Universal Inc.</td>
            <td><div class="TableLabel">Vat nr°</div>ABC123456789</td>
            <td><div class="TableLabel">Country</div>USA</td>
            <td><div class="TableLabel">City</div>New York</td>
            <td><div class="TableLabel">Agent</div>Christia Horner</td>
            <td><button class="TableToggleButton">See more</button></td>
        </tr>
        <!-- <tr class="TableTogggleRow">
            <td colspan="6">
                <ul>
                    <li><span>Address</span> <span>Fashion Center Building 525 7th Ave - NY</span></li>
                    <li><span>Referent Name</span> <span>James Brennstein</span></li>
                    <li><span>Referent E-mail</span> <span><a href="mailto:">brennstein@universal.com</a></span></li>
                    <li><span>Phone</span> <span><a href="Tel:">+1 212 221 7515</a></span></li>
                    <li><span>Note:</span> <span>...</span></li>
                </ul>
            </td>
        </tr> -->
        <!-- Fine repeater -->
        <!-- Inizio repeater -->
        <tr>
            <td><div class="TableLabel">Company name</div>Megafirm Gmbh</td>
            <td><div class="TableLabel">Vat nr°</div>XYZ123456789</td>
            <td><div class="TableLabel">Country</div>Norvay</td>
            <td><div class="TableLabel">City</div>Oslo</td>
            <td><div class="TableLabel">Agent</div>Sigmund Johansson</td>
            <td><button class="TableToggleButton">See more</button></td>
        </tr>
        <!-- <tr class="TableTogggleRow">
            <td colspan="6">
                <ul>
                    <li><span>Address</span> <span>Stenersgata 1, 0050 Oslo</span></li>
                    <li><span>Referent Name</span> <span>Anne Bjørndal</span></li>
                    <li><span>Referent E-mail</span> <span><a href="mailto:">annebjørndal@megafirm.com</a></span></li>
                    <li><span>Phone</span> <span><a href="Tel:">+47 458 62 427</a></span></li>
                    <li><span>Note:</span> <span>Lorem ipsum dolor sit amet consectetur adipiscing elit ipsum dolor sit amet</span></li>
                </ul>
            </td>
        </tr> -->
        <!-- Fine repeater -->
    </tbody>
</table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
  export default {
  components: {  },
    metaInfo: () => ({
    title: 'Accounts'}),
    created() {
    }    
};
</script>