<template>
  <div class="login">
    <section class="LoginSection">
        <video autoplay loop muted poster="/cmsdata/Poster.jpg">
            <source src="/media/Herovideo.mp4" type="video/mp4" />
            <source src="/media/Herovideo.webm" type="video/webm" />
        </video>
        <div class="BackgroundOverlay">
            <form @submit.prevent="login" class="Frame Form LoginPanel">
                <fieldset>
                    <legend><h1><span>Nubess Reseller Hub</span><img src="/assets/images/Logo.png" srcset="/assets/images/LogoRetina.png 2x" alt="Nubess Reseller Hub" /></h1></legend>
                    <div class="FormRow">
                        <div class="FormBoxWide">
                            <label>Username</label>
                            <input v-model="auth.username" type="text" id="username" name="" placeholder="Username">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBoxWide">
                            <label>Password</label>
                            <input v-model="auth.password" type="password" id="password" name="" placeholder="Password">
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="FormBoxWide">
                            <label class="PasswordRecovery"><a href="/">Forgot password?</a></label>
                            <div class="Button">
                                <input v-on:click="login()" type="button" id="login" name="" value="Login">
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>                
    </section>
  </div>
</template>

<script>
export default {
    metaInfo: () => ({
    title: 'Login'}),
        name: 'Login',
        data() {
            return {
                auth: {
                    username: "",
                    password: "",
                }
            }
        },
        beforeMount () {
            localStorage.clear();
        },
        methods: {
            login() {
                if( this.auth.username != "" && this.auth.password != "") {
                let user = this.auth.username
                let pass = this.auth.password
                    this.$store.dispatch('loginAction', { user, pass })
            }
        }
    }
}
</script>