<template>
        <section class="LightGrey" style="position:relative;">
            <div class="Container">
                <div class="Box">
                    <div class="DataMainHeader">
                        <div class="Index"><h2>Orders/Projects</h2></div>
                        <div class="AddContainer">
                            <div  class="AddPanelOpenButton"><span>Add new</span><button @click="ShowNewPanel()"><img src="/assets/images/AddWhiteIcon.png" srcset="/assets/images/AddWhiteIconRetina.png 2x" alt=""/></button></div>    
                            <div class="AddPanel">
                                <div class="AddPanelForm Projects">
                                    <div  class="AddPanelCloseButton"><button @click="ShowNewPanel()"><img src="/assets/images/CloseWhiteIcon.png" srcset="/assets/images/CloseWhiteIconRetina.png 2x" alt=""/></button></div>
                                    <form class="Form">
                                    <fieldset>
                                        <legend>Order request</legend>
                                        <div class="FormRow">
                                            <div class="FormBox50">
                                                <label>Label x input type=text</label>
                                                <input type="text" id="" name="" placeholder="Your placeholder">
                                            </div>
                                            <div class="FormBox50">
                                                <label>Label x input type=password</label>
                                                <input type="password" id="" name="" placeholder="Your placeholder">
                                            </div>
                                        </div>
                                        <div class="FormRow">
                                            <div class="FormBoxWide">
                                                <label>Label x select</label>
                                                <select class="CustomSelect" name="">
                                                <option value="">Option A</option>
                                                <option value="">Option B</option>
                                                <option value="">Option C</option>
                                                <option value="">Option D</option>
                                                <option value="">Option E</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="FormRow">
                                            <div class="FormBoxWide">
                                                <label>Label x input type=text</label>
                                                <input type="text" id="" name="" placeholder="Your placeholder">
                                            </div>
                                        </div>
                                        <div class="FormRow">
                                            <div class="FormBox50">
                                                <label>Label x input type=text</label>
                                                <input type="text" id="" name="" placeholder="Your placeholder">
                                            </div>
                                            <div class="FormBox50">
                                                <label>Label x input type=password</label>
                                                <input type="password" id="" name="" placeholder="Your placeholder">
                                            </div>
                                        </div>
                                        <div class="FormRow">
                                            <div class="FormBoxWide">
                                                <label>Label x textarea</label>
                                                <textarea placeholder="Write a text"></textarea>
                                            </div>
                                        </div>
                                        <div class="FormRow">
                                            <div class="FormBoxWide">
                                                <div class="FormCheckBox">
                                                    <div class="CheckField"><input type="checkbox" name="" /><span class="Checkmark"></span></div>
                                                    <div class="CheckLabel">Lorem ipsum dolor sit amet</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="WhiteSpace">&nbsp;</div>
                                        <div class="FormRow">
                                            <div class="FormBoxWide">
                                                <div class="FormRadioBox">
                                                    <div class="RadioField"><input type="radio" name="" checked="checked" /><span class="Radiomark"></span></div>
                                                    <div class="RadioLabel">Lorem ipsum dolor sit amet</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="WhiteSpace">&nbsp;</div>
                                        <div class="FormRow">
                                            <div class="FormBoxWide">
                                                <div class="TwinButtons">
                                                    <div class="Button">
                                                        <input type="button" id="" name="" value="Delete">
                                                    </div>
                                                    <div class="Button">
                                                        <input type="button" id="" name="" value="Confirm request">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>


                                </div>

                            </div>
                        </div>
                    </div>     
                </div>
                <div class="Box">
                    <div class="Binder DataMainTable">
                        <div class="Frame DataTabHolder">
                            <div class="DataMainTableHead DataTabButtonBox">
                                <div class="DataMainTableSplit">
                                    <a class="DataTabButton" @click="$router.push({ path: '/organization/subscriptions' })" v-bind:class="[Type === 'subscriptions' ? 'SelectedItem' : '']"><span>Subscription</span></a>
                                    <a class="DataTabButton" @click="$router.push({ path: '/organization/projects' })" v-bind:class="[Type === 'projects' ? 'SelectedItem' : '']"><span>Projects</span></a>
                                </div>
                                <div class="SearchbarContainer">
                                    <div class="SearchBar">
                                        <form class="Form">
                                            <h5>Search by code or topics</h5>
                                            <div class="FormRow">
                                                <div class="FormBox75">
                                                    <input type="text" id="" name="" placeholder="Search...">
                                                </div>
                                                <div class="FormBox25">
                                                    <div class="Button">
                                                        <input type="submit" id="" name="" value="">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="DataMainTableContent DataTabContentBox">
                                <div class="DataTabContent">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0" class="LicensesTable">

                                        <!-- Inizio subscriptions -->

                                    <tbody v-if="Type==='subscriptions'">
                                        <tr>
                                            <th scope="col">Code</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Company name</th>
                                            <th scope="col">Contract name</th>
                                            <th scope="col">Agent</th>
                                            <th scope="col">Valid from</th>
                                            <th scope="col">Expiry date</th>
                                            <th scope="col">Value</th>
                                            <th scope="col">&nbsp;</th>
                                        </tr>
                                    
                                    </tbody>
                                    <template v-if="Type==='subscriptions'">
                                    <row-subscriptions v-for="Subscription in Subscriptions" :key="Subscription.id" :Subscription="Subscription"/>
                                    </template>
                                    
                                        <!-- Fine subscriptions -->

                                        <!-- Inizio projects -->

                                    <tbody v-if="Type==='projects'">
                                        <tr>
                                            <th scope="col">Code</th>
                                            <th scope="col">Company name/end user</th>
                                            <th scope="col">Client Referent</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Start date</th>
                                            <th scope="col">Delivery date</th>
                                            <th scope="col">&nbsp;</th>
                                        </tr>
                                        
                                    </tbody>
                                    <template  v-if="Type==='projects'">
                                        <row-projects v-for="Project in Projects" :key="Project.id" :Project="Project" />
                                    </template>
                                        <!-- Fine projects -->

                                        </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import RowSubscriptions from '@/components/Projects/Row-Subscriptions.vue'
import RowProjects from '@/components/Projects/Row-Projects.vue'
import AxiosClient from '../service/api.js'

export default {
  name: 'Projects',
  props: {
      Type: String
    },
    components:{
       RowSubscriptions,
       RowProjects
    },
   data() {
            return {
                Switch: "Open",
                Requests: [],
                Customers: [],
                Projects: [],
                Subscriptions: [],
                Customer:String,
                Project:String,
                Subject:String,
                Messagge:String,
                File:String
            }
   },
  methods: {
    getReportList() {
        AxiosClient
            .get('reports')
            .then(response => ( this.Requests = response.data.Data))
    },
    getCustomers(){   
        AxiosClient
            .get('endusers')
            .then(response => ( this.Customers = response.data.Data))
        },
    getProjects(){    
        AxiosClient
            .get('projects')
            .then(response => ( this.Projects = response.data.Data))
        },
    getSubscriptions(){    
        AxiosClient
            .get('Subscriptions')
            .then(response => ( this.Subscriptions = response.data.Data))
        }
    },
    mounted() {
    //   this.getReportList()
    this.getCustomers()
    this.getProjects()
    this.getSubscriptions() 

    },
    
    watch: {
        Customer: function (oldvalue, newvalue){
               this.getProjects(newvalue) 
        }
  },
}
</script>