<template>
<div class="Welcome">
        <section class="LightGrey">
            <div class="Container NewsDetail">
                <div class="Box SpanW7 SpanH10 Center">
                    <a href="#" @click="$router.go(-1)"> &lsaquo; Torna al Bollettino</a>
                    <h1>{{Post.Titolo}}</h1>
                    <h2>{{Post.Descrizione}}</h2>
                    <div class="NewsTagLine"><time>{{moment(Post.dataPubblicazione).format("DD/MM/YY")}}</time>
                    <span><i v-for="Tag in Post.Tags" :key="Tag">{{Tag}}</i></span>
                    </div>
                    <div class="NewsAuthor">
                        <div class="AutorName"><span v-html="Post.Autore"></span></div>
                    </div>
                    <!-- <a class="Play fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbA.jpg" alt=""/></a> -->                    
                    <div class="NewsImage">
                        <img :src="Post.PathImmagine" alt=""/>
                    </div>
                    <div class="MainText" v-html="Post.DescrizioneEstesa">

                    </div>
                </div>
            </div>
        </section>
</div>
</template>

<script>
export default {
  name: 'NewsStand',
   data() {
            return {
                Post: this.$route.params.Details,
          }
   },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>