<template>
<div class="Frame">
    <div class="NewsPreviewItem">
        <div class="NewsPreviewItemImage">
        <router-link v-bind:to="{ name: 'BulletinDetail', params: { Details: Article }}">
            <img :src="Article.PathImmagine" alt="" />
        </router-link>
        </div>
        <div class="NewsPreviewItemText">
            <div class="NewsTagLine">
                <time>{{ Article.DataPubblicazione | date }}</time>
                <span>
                    <a href="#" v-for="Tag in Article.Tags" :key="Tag">{{Tag}}</a>
                </span>
            </div>
            <h2>{{ Article.Titolo | text }}</h2>
            <p>{{ Article.Descrizione | text }}</p>
            <div class="Link">
                <router-link v-bind:to="{ name: 'BulletinDetail', params: { Details: Article }}">{{$t('BULLETIN.READ_MORE')}}</router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
    return {
        Image:'',
        CurrentArticle:[]
    };
    },
    props: ['Article'],
    mounted() {
        this.Article.PathImmagine = this.$store.getters['getApiPath'] + "Image/news?intern=" + this.$store.getters['getUserIntern'] +"&partner=" + this.$store.getters['getUserPartner'] + "&user=" + this.$store.getters['getUserPartner'] + "&news=" + this.Article.Id
    },
}
</script>