<template>
  <tbody>
        <tr>
            <td><div class="TableLabel">Code</div>{{Project.id}}</td>
            <td><div class="TableLabel">Company name/end user</div>{{projectDetails.companyName}}</td>
            <td><div class="TableLabel">Client Referent</div>{{projectDetails.referentName}}</td>
            <td><div class="TableLabel">Subject</div>{{projectDetails.subject}}</td>
            <td><div class="TableLabel">Start date</div>{{projectDetails.dataInizioPrevista | date}}</td>
            <td><div class="TableLabel">Delivery date</div>{{projectDetails.dataFinePrevista | date}}</td>
            <td><div class="Button Orange Small"><router-link v-bind:to="{ name: 'ProjectsDetails', params: { Details: projectDetails }}">Show Details</router-link></div></td>
        </tr>
    <tr v-if="showMore">
      <td colspan="8">
        <div class="TableTab">
          <div class="TabHolder">
            <div class="TabButtonBox">
              <div class="TabButton" v-bind:class="[activetab === 1 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 1"
                >
                  Description
                </h4>
              </div>
              <div class="TabButton" v-bind:class="[activetab === 2 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 2"
                >
                  Related ticket
                </h4>
              </div>
              <div class="TabButton" v-bind:class="[activetab === 3 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 3"
                >
                  Customer service notes
                </h4>
              </div>
              <div class="TabButton" v-bind:class="[activetab === 4 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 4"
                >
                  Closure
                </h4>
              </div>
            </div>
            <div class="TabContentBox">
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 1">
                <div class="TabTextBox">
                  <p>
                    {{ Request.descrizione | default-text}}
                  </p>
                </div>
              </div>
              <!-- Fine panello a scomparsa -->
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 2">
                <!-- Inizio tabella interna -->
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <th scope="col">Ticket Nr./Code</th>
                      <th scope="col">Date</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Request by</th>
                      <th scope="col">Status</th>
                      <th scope="col">Presumed closure date</th>
                      <th scope="col">Type</th>
                    </tr>
                    <tr>
                      <td scope="col">
                        <div class="TableLabel">Ticket Nr./Code</div>
                        ABC 102 456
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Date</div>
                        04/06/2021 15:41
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Subject</div>
                        Lorem ipsum dolor
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Request by</div>
                        Max Power
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Status</div>
                        Recorded
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Presumed closure date</div>
                        05/06/2021
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Type</div>
                        Lorem ipsum dolor
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Fine tabella interna -->
              </div>
              <!-- Fine panello a scomparsa -->
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 3">
                <div class="TabTextBox">
                  <p>
                      {{Request.noteCS | default-text}}
                  </p>
                </div>
              </div>
              <!-- Fine panello a scomparsa -->
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 4">
                <div class="TabTextBox">
                  <p>
                    {{Request.noteChiusura | default-text}}
                  </p>
                </div>
              </div>
              <!-- Fine panello a scomparsa -->
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import AxiosClient from '@/service/api.js'
export default {
  props: ['Project'],
   data() {
        return {
            showMore: false,
            activetab: 1,
            projectDetails: ''
        }
   },
   methods: {
     showMoreFn() {
      if  ( this.showMore ) {
        this.showMore = false
      } else {
        this.showMore = true
      }
     },
    getProjectsDetails(){
      AxiosClient
        .get("projects/details?project=" + this.Project.id)
        .then(response => (
            this.projectDetails = response.data.Data))
          }
   },
   mounted(){
     this.getProjectsDetails()
   }
}
</script>
