<template>
<div>
<section class="HeroImage">
            <img src="/cmsdata/HeroImage.jpg" alt=""/>
            <div class="Container">
                <div class="Box FeaturedVideo">
                    <div class="FeaturedVideoText">
                        <i>Nubess Academy <span>Featured Video</span></i>
                        <h2>Guide on how to invoice T&amp;M Projects</h2>
                        <h3>(&amp; Why it Matters More than You Think)</h3>
                    </div>
                    <div class="FeaturedVideoLink"><a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">&nbsp;</a></div>
                </div>
            </div>
        </section>
        <section class="LightGrey SmallPadding">
            <div class="Container">
                <div class="Box SearchbarContainer">
                    <div class="SearchBar">
                        <form class="Form">
                            <h5>Search by topics</h5>
                            <div class="FormRow">
                                <div class="FormBox75">
                                    <input type="text" id="" name="" placeholder="Search...">
                                </div>
                                <div class="FormBox25">
                                    <div class="Button">
                                        <input type="submit" id="" name="" value="">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="Box">
                    <div class="ActiveTag">
                        <div class="Products">
                            <h4>Filter by product:</h4>
                            <ul>
                                <li><a href="#" class="SelectedItem">all</a></li>
                                <li><a href="#">Nubess Me</a></li>
                                <li><a href="#">Nubess Hr</a></li>
                                <li><a href="#">Nubess Bi</a></li>
                            </ul>
                        </div>
                        <div class="Topics">
                            <h4>Filter by topic:</h4>
                            <ul>
                                <li><a href="#" class="SelectedItem">all</a></li>
                                <li><a href="#">Lorem ipsum dolor</a></li>
                                <li><a href="#">Lorem ipsum dolor</a></li>
                                <li><a href="#">Lorem ipsum dolor</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="Box">
                    <div class="ActiveFilter">
                        <div>
                            <h4>Active filter:</h4>
                            <ul>
                                <li>finance <span><img src="/assets/images/FilterDeleteIcon.png" srcset="/assets/images/FilterDeleteIconRetina.png 2x" alt="Delete filter"/></span></li>
                                <li>trouble <span><img src="/assets/images/FilterDeleteIcon.png" srcset="/assets/images/FilterDeleteIconRetina.png 2x" alt="Delete filter"/></span></li>
                                <li>marketing <span><img src="/assets/images/FilterDeleteIcon.png" srcset="/assets/images/FilterDeleteIconRetina.png 2x" alt="Delete filter"/></span></li>
                            </ul>
                        </div>
                        <button class="Small CustomColor">Remove all filters</button>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="Container">
                <div class="Box">
                    <!-- Inizio repeater -->
                    <div class="VideoGroup">
                        <div class="VideoGroupIntro">
                            <div class="Index">
                                <small>Nubess Me</small>
                                <h2>Name of the video Group</h2>
                            </div>
                        </div>
                        <div class="VideoGroupItemBox">
                            <div class="ClassicIkarus owl-carousel owl-theme">
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbA.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>1h 32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbB.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbC.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbD.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbE.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbF.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                            </div>
                        </div>
                    </div>
                    <!-- Fine repeater -->
                    <!-- Inizio repeater -->
                    <div class="VideoGroup">
                        <div class="VideoGroupIntro">
                            <div class="Index">
                                <small>Nubess Hr</small>
                                <h2>Name of the video Group</h2>
                            </div>
                        </div>
                        <div class="VideoGroupItemBox">
                            <div class="ClassicIkarus owl-carousel owl-theme">
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbA.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>1h 32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbB.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                            </div>
                        </div>
                    </div>
                    <!-- Fine repeater -->
                    <!-- Inizio repeater -->
                    <div class="VideoGroup">
                        <div class="VideoGroupIntro">
                            <div class="Index">
                                <small>Nubess Bi</small>
                                <h2>Name of the video Group</h2>
                            </div>
                        </div>
                        <div class="VideoGroupItemBox">
                            <div class="ClassicIkarus owl-carousel owl-theme">
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbA.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>1h 32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbB.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbC.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbD.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbE.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                                <!-- Inizio repeater -->
                                <div class="Frame VideoItem">
                                    <div class="VideoItemImage">
                                        <a class="fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0"><img src="/cmsdata/NewsThumbF.jpg" alt=""/></a>
                                    </div>
                                    <div class="VideoItemText">
                                        <time>07/06/2021<i>32min.</i></time>
                                        <h3>Lorem ipsum dolor sit amet cinsectetur adipiscing elit</h3>
                                        <div class="VideoItemButtons">
                                            <a class="VideoBtn fancybox.iframe FancyVideo" href="http://player.vimeo.com/video/34792993?title=0&amp;byline=0&amp;portrait=0">Watch the video</a>
                                            <span>&nbsp;</span>
                                            <a class="QuizBtn" href="#">Handbook</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fine repeater -->
                            </div>
                        </div>
                    </div>
                    <!-- Fine repeater -->
                </div>
            </div>
        </section>
</div>
</template>

<script>
  export default {
  components: {  },
    metaInfo: () => ({
    title: 'Academy'}),
    created() {
    }    
};
</script>