import Vue from 'vue'
import store from '@/store'

import axios from 'axios'

const AxiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 1000,
  headers: { 'Content-Type': 'application/json' },
})

// Or you can use an interceptor if adding tokens etc.
// ======================================
axios.defaults.params = {};

AxiosClient.interceptors.request.use(async config => {
  store.commit('loadingData')
  config.headers.common.Authorization = `Bearer ` + store.getters['getToken']
  config.headers['Accept-Language']=store.getters['getCurrentLocale']
  config.params = { 
    'intern': store.getters['getUserIntern'],
    'partner': store.getters['getUserPartner'],
    'user': store.getters['getUserName'],
  }  
  store.commit('loadingData')
  return config
})

AxiosClient.interceptors.response.use((response) => {
  console.log (response);
  store.commit('dataLoaded')
  return response;
}, (error) => {
  console.log(error);
  store.commit('dataLoaded')
  switch (error.response.status) {
    case 401:
      store.dispatch('LogOut')
      break;
    case 404:
      store.dispatch('not_found')
      break;
    default:
      store.dispatch('general_error')
      break;
  }

  if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});
Vue.prototype.$http = AxiosClient

export default AxiosClient