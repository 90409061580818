<template>
<div class="NewsIkarusItem">
    <div class="NewsIkarusItemImage">
        <router-link v-bind:to="{ name: 'BulletinDetail', params: { Details: Article }}">
            <img :src="Article.PathImmagine" alt="" />
        </router-link>
    </div>
    <div class="Box NewsIkarusItemText">
        <div class="NewsTagLine"><time>07/06/2021</time> <span><i>finance</i><i>trouble</i><i>marketing</i></span></div>
        <h2>Resource management &amp; soft skills</h2>
        <p>In organizational studies, resource management is the efficient and effective development of an organization's resources when...</p>
        <div class="Link"><a href="bulletindetail.php">Read more</a></div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
    return {
        Image:'',
        CurrentArticle:[]
    };
    },
    props: ['Article'],
    mounted() {
        this.Article.PathImmagine = this.$store.getters['getApiPath'] + "Image/news?intern=" + this.$store.getters['getUserIntern'] +"&partner=" + this.$store.getters['getUserPartner'] + "&user=" + this.$store.getters['getUserPartner'] + "&news=" + this.Article.Id
    },
}
</script>