<template>
        <section class="LightGrey">
            <div class="Container">
                <div class="Box">
                    <div class="Index"><h2>Marketing</h2></div>
                </div>
                <div class="Box">
                    <div class="ActiveTag Products">
                        <div>
                            <h4>Filter by product:</h4>
                            <ul>
                                <li><a href="#" class="SelectedItem">all</a></li>
                                <li><a href="#">Nubess Me</a></li>
                                <li><a href="#">Nubess Hr</a></li>
                                <li><a href="#">Nubess Bi</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="Box">
                    <div class="Binder Marketing">
                        <div class="Frame">
                            <!-- Inizio repeater -->
                            <div class="MarketingGroup"> 
                                <div class="ToggleButton">Brochures</div>
                                <div class="ToggleBox">
                                    <ul>
                                        <li v-for="Brochure in this.Brochures" :key="Brochure.id"><a v-bind:href="Brochure.Link">{{Brochure.Descrizione}}<span>{{Brochure.DataDocumento | date }}</span><i>{{Brochure.Prodotto}}</i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- Fine repeater -->
                            <!-- Inizio repeater -->
                            <div class="MarketingGroup"> 
                                <div class="ToggleButton">Powerpoints</div>
                                <div class="ToggleBox">
                                    <ul>
                                        <li v-for="Powerpoint in this.Powerpoints" :key="Powerpoint.id"><a v-bind:href="Powerpoint.Link">{{Powerpoint.Descrizione}}<span>{{Powerpoint.DataDocumento | date }}</span><i>{{Powerpoint.Prodotto}}</i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- Fine repeater -->
                            <!-- Inizio repeater -->
                            <div class="MarketingGroup"> 
                                <div class="ToggleButton">Video</div>
                                <div class="ToggleBox">
                                    <ul>
                                        <li v-for="Video in this.Videos" :key="Video"><a v-bind:href="Video.Link">{{Video.Descrizione}}<span>{{Video.DataDocumento | date }}</span><i>{{Video.Prodotto}}</i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- Fine repeater -->

                            <!-- <div class="MarketingGroup"> 
                                <div class="ToggleButton">Stationary</div>
                                <div class="ToggleBox">
                                    <ul>
                                        <li v-for="Stationary in this.Stationarys" :key="Stationary"><a href="#">Lorem ipsum dolor sit amet consectetur <span>12/07/2021</span><i>Nubess Me</i></a></li>
                                    </ul>
                                </div>
                            </div> -->


                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import store from '@/store'
import AxiosClient from '../service/api.js'

  export default {

   data() {
       return {
           FilterByProduct: "",
           Materials: []
           }
   },

  methods: {
    fetchMaterials() {
            AxiosClient
                .get('Marketing')
                .then(response => (
                    this.Materials = response.data.Data))
    },

  },
  computed: {
    Brochures() {
        return this.Materials.filter(function(Mat) {
         if ( !Mat.Link ) {
              Mat.Link = store.getters['getApiPath'] + "Media/GetAllegato?idDocumento="+ Mat.IdDocumento 
            }
         return Mat.CodiceTipologia == "BRO";
        })
        
    },
    Powerpoints() {
        return this.Materials.filter(function(Mat) {
         return Mat.CodiceTipologia == "PRE";
        })
    },
    Videos() {
        return this.Materials.filter(function(Mat) {
         return Mat.CodiceTipologia == "VID";
        })
    },
    Stationarys() {
        return this.Materials.filter(function(Mat) {
         return Mat.CodiceTipologia == "STN";
        })
    },
  },
    mounted(){
        this.fetchMaterials()
        this.checkLink()
    },

    metaInfo: () => ({
    title: 'Marketing Materials'})
};
</script>