<template>
    <div class="TimezoneBox">
        <div class="Timezone">
            <div class="TimezoneIndex"><strong>Lucca</strong> <span>Italy</span></div>
            <div class="TimezoneTime"><time> {{Lucca}}</time> <span>CET</span></div>
        </div>
        <div class="Timezone">
            <div class="TimezoneIndex"><strong>{{Citta}}</strong> <span>{{Nazione}}</span></div>
            <div class="TimezoneTime"><time>{{Reseller}}</time> <span></span></div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import 'moment-timezone'
export default {
  name: 'TimezoneClock',
  data () {
    return {
      Lucca: '',
      Reseller: '',
    }
  },
  props: ['Nazione', 'Citta'],
  methods: {
    setTime () {
      setInterval(() => {
        this.Lucca = moment().format("HH:mm:ss");
        let Timezone = moment.tz.guess();
        this.Reseller = moment().utcOffset(Timezone).format("HH:mm:ss");
      }, 1000)
    }
  },
  mounted () {
    this.setTime()
  }
}
</script>