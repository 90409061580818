<template>
    <div class="LayoutHolder" style="padding-top: 60px" id="">
        <section class="LightGrey " style="position:relative;">
            <div class="Container">
                <div class="Box">
                    <a href="#" @click="$router.go(-1)"> &lsaquo; Back</a>
                    <div class="DataMainHeader">
                        <div class="Index"><h2><small>COD.  </small>{{Details.id}}</h2></div>
                    </div>     
                </div>
                <div class="Box">
                    <div class="Binder DataMainTable">
                        <div class="Frame">
                            <div class="ProjectDataBox">
                                <div class="ProjectData">
                                    <h3>Project info</h3>
                                    <div class="Binder">
                                        <div class="DataItem">
                                            <h4>Start date</h4>
                                            <div>{{ Details.dataInizioPrevista | date }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Delivery date</h4>
                                            <div>{{ Details.dataFinePrevista | date }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Status</h4>
                                            <div>{{ Details.status | text}}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Project Manager</h4>
                                            <div>{{ Details.projectManager | text }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ProjectData">
                                    <h3>Project description</h3>
                                    <div class="Binder">
                                        <div class="DataItem">
                                            <h4>Description</h4>
                                            <div>{{ Details.subject | text }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Project value</h4>
                                            <div>{{ Details.projectValue | EUR }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Invoiced Amount</h4>
                                            <div>{{ Details.invoicedAmount | EUR }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ProjectData">
                                    <h3>Company info</h3>
                                    <div class="Binder">
                                        <div class="DataItem">
                                            <h4>Company name</h4>
                                            <div>{{ Details.companyName | text }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Address</h4>
                                            <div>{{ Details.address | text }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Referent Name:</h4>
                                            <div>{{ Details.referentName | text }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Referent e-mail:</h4>
                                            <div>{{ Details.referentEmail | text }}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Referent phone:</h4>
                                            <div>{{ Details.referentPhone | text }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section>
            <div class="Container">
                <div class="Box">
                    <div class="Index"><h2>Team</h2></div>
                </div>
                <div class="Box">
                    <div class="Binder QuickContacts">
                        <div class="Frame">
                            <div class="QuickContactData">
                                <div class="QuickContactProfile">
                                    <img src="/cmsdata/UserProfile03.png" alt="Image" />
                                    <div>
                                        <h3>Gudrun Vandenbussche</h3>
                                        <h4><span>Project Manager</span><i>Nubess</i></h4>
                                    </div>
                                </div>
                                <ul>
                                    <li><a href="tel:+39123456789"><img src="/assets/images/PhoneIcon.png" srcset="/assets/images/PhoneIconRetina.png 2x" alt="" /><span>+39 339 11 44 555</span></a></li>
                                    <li><a href="mailto:info@info.com"><img src="/assets/images/EmailIcon.png" srcset="/assets/images/EmailIconRetina.png 2x" alt="" /><span>g.vandenbussche@nubess.com</span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="Frame">
                            <div class="QuickContactData">
                                <div class="QuickContactProfile">
                                    <img src="/cmsdata/UserProfile06.png" alt="Image" />
                                    <div>
                                        <h3>Jason John Lord</h3>
                                        <h4><span>Sales Manager</span><i>Nubess</i></h4>
                                    </div>
                                </div>
                                <ul>
                                    <li><a href="tel:+39123456789"><img src="/assets/images/PhoneIcon.png" srcset="/assets/images/PhoneIconRetina.png 2x" alt="" /><span>+39 339 11 44 555</span></a></li>
                                    <li><a href="mailto:info@info.com"><img src="/assets/images/EmailIcon.png" srcset="/assets/images/EmailIconRetina.png 2x" alt="" /><span>jjlord@nubess.com</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="LightGrey">
            <div class="Container">
                <div class="Box">
                    <div class="Index"><h2>Milestone</h2></div>
                </div>
                <div class="Box">
                    <div class="Binder DataMainTable">

                        <div class="Frame" v-for="Milestone in Milestones" :key="Milestone.descrizione">
                            <div class="ProjectDataBox">
                                <div class="MilestoneData">
                                    <h3>{{Milestone.descrizione}}</h3>
                                    <div class="Binder">
                                        <div class="DataItem">
                                            <h4>Type</h4>
                                            <div>{{Milestone.tipo}}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Value</h4>
                                            <div>{{Milestone.value | EUR}}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Price</h4>
                                            <div>{{Milestone.price | EUR}}</div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Completion:</h4>
                                            <div>
                                                <div class="ProgressBarBox">
                                                    <div class="ProgressBar"><span v-bind:style="{ width: Milestone.percCompletion + '%' }" >&nbsp;</span></div>
                                                    <div class="ProgressVal"><span>{{Milestone.percCompletion}}<small>%</small></span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="DataItem">
                                            <h4>Invoicing:</h4>
                                            <div>
                                                <div class="ProgressBarBox">
                                                    <div class="ProgressBar Invoicing"><span v-bind:style="{ width: Milestone.percCompletion + '%' }">&nbsp;</span></div>
                                                    <div class="ProgressVal"><span>{{Milestone.percInvoiced}}<small>%</small></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="Container">
                <div class="Box">
                    <div class="Index"><h2>Project report &amp; documentation</h2></div>
                </div>
                <div class="Box">
                    <div class="Binder ReportsDownloads">
                        <div class="Frame">
                            <div class="ReportsDownloadsList">
                                <h3>Project reports</h3>
                                <ul>
                                    <li v-for="Repo in Reports" :key="Repo.id" >
                                        <a href="javascript:alert('Non disponibile')"><span>{{Repo.oggetto}}</span> <time>{{Repo.data | date}}</time></a></li>
                                </ul>
                            </div>
                            <div class="ReportsDownloadsList">
                                <h3>General documentation</h3>
                                <ul>
                                    <li v-for="Doc in Documents" :key="Doc.id"><a href="doc/document?idDoc=49"><span>{{Doc.nome}}</span> <time>{{Doc.nome}}</time></a></li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="Frame">
                            <div class="ReportsUpload">
                                <h3>Upload your files</h3>
                                <div class="UploadForm">
                                    <form class="Form" action="/action_page.php">
                                        <div class="FormRow">
                                            <div class="FormBoxWide">
                                               <input type="file" id="myFile" name="filename"><div class="Button Small CustomColor2"><input type="submit" value="Invia il file"></div>
                                            </div>
                                        </div>  
                                    </form>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AxiosClient from '@/service/api.js'
export default {
  name: 'Projects',
  props: {

    },
    components:{
    },

    data() {
        return {
            Details: this.$route.params.Details,
            Team: [],
            Milestones: [],
            Reports: [],
            Documents: []
            }
    },
      methods: {
        getMilestones() {
            AxiosClient
                .get( "projects/milestone?project=" + this.Details.id )
                .then( response => (this.Milestones = response.data.Data ) )
        },
        getReports() {
            AxiosClient
                .get( "projects/reports?project=" + this.Details.id )
                .then( response => ( this.Reports = response.data.Data) )
        },
        getDocuments() {
            AxiosClient
                .get( "projects/docs?project=" + this.Details.id )
                .then( response => ( this.Documents = response.data.Data ) )
        },
    },
    mounted() {
        if ( this.Details ){
            this.getMilestones()
            this.getReports()
            this.getDocuments()
        } else{
            this.$router.push({ path: '/organization' })
        }

    }
}
</script>