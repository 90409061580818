<template>
  <div class="dashboard">
        <section class="Welcome">
            <div class="Container">
                <div class="WelcomeText">
                    <div class="Box">
                        <i>{{ $t('HOME.SALUTATION', { user: displayName()}) }}<span></span></i>
                        <h1 v-html="$t('HOME.WELCOME_MSG')"></h1>
                        <world-clocks :Nazione='ResellerNation()' :Citta="ResellerCity()"/>
                    </div>
                </div>
                
                <!-- <div class="WelcomePlaceHolder"><img src="/cmsdata/NewsIkarusPlaceholder.png" alt=""/></div> -->
               
                <div class="WelcomeNews">
                    <div class="WelcomeNewsLabel"><span>News from our network</span></div>
                    <div class="NewsIkarus">
                    <!-- Inizio carosello/notizie (NewsIkarus) -->
                         <bulletin-card-home v-for="News in Newstand" :key="News.id" :Article="News" />

                    </div>
                    <!-- Fine carosello/notizie (NewsIkarus) -->
                </div>
            </div>   
        </section>
  </div>
</template>

<script>

import WorldClocks from '../components/WorldClocks.vue';
import AxiosClient from '../service/api.js'
import BulletinCardHome from '../components/BullettinCardHome.vue'

export default {
    data: function() {
        return {
        Newstand:[]
        
        };
    },
    
    components: { WorldClocks, BulletinCardHome},
      methods: {
        getNews() {
            AxiosClient
                .get( 'news' )
                .then( response => (this.Newstand = response.data.Data ) )
        },
      },
    mounted() {
        this.getNews()
        let IkarusScript = document.createElement('script')
        IkarusScript.setAttribute('src', '/assets/js/Ikarus.js');
        document.body.appendChild(IkarusScript)
    },
    metaInfo: () => ({
    title: 'Dashboard'})
};
</script>