<template>
  <tbody>
    <tr>
      <td>
        <div class="TableLabel">{{$t('ASSISTANCE.TABLE.CODE')}}</div>
        {{ Request.Id }}
      </td>
      <td>
        <div class="TableLabel">{{$t('ASSISTANCE.TABLE.DATE')}}</div>
        {{ Request.Data | date }}
      </td>
      <td>
        <div class="TableLabel">{{$t('ASSISTANCE.TABLE.SUBJECT')}}</div>
        {{ Request.Oggetto }}
      </td>
      <td>
        <div class="TableLabel">{{$t('ASSISTANCE.TABLE.REQUESTED_BY')}}</div>
        {{ Request.Contatto }}
      </td>
      <td>
        <div class="TableLabel">{{$t('ASSISTANCE.TABLE.PROJECT_NAME')}}</div>
        {{ Request.Commessa }}
      </td>
      <td>
        <div class="TableLabel">{{$t('ASSISTANCE.TABLE.COMPANY_NAME')}}</div>
        {{ Request.Company }}
      </td>
      <td>
        <button class="TableToggleButton" v-show="showMore" v-on:click="showMoreFn()" >{{ $t('ASSISTANCE.TABLE.SHOW_LESS') }}</button>
        <button class="TableToggleButton" v-show="!showMore" v-on:click="showMoreFn()">{{ $t('ASSISTANCE.TABLE.SHOW_MORE') }}</button>

      </td>
    </tr>
    <tr v-if="showMore">
      <td colspan="8">
        <div class="TableTab">
          <div class="TabHolder">
            <div class="TabButtonBox">
              <div class="TabButton" v-bind:class="[activetab === 1 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 1"
                >
                  Description
                </h4>
              </div>
              <div class="TabButton" v-bind:class="[activetab === 2 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 2"
                >
                  Related ticket
                </h4>
              </div>
              <div class="TabButton" v-bind:class="[activetab === 3 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 3"
                >
                  Customer service notes
                </h4>
              </div>
              <div class="TabButton" v-bind:class="[activetab === 4 ? 'SelectedItem' : '']">
                <h4
                  v-on:click="activetab = 4"
                >
                  Closure
                </h4>
              </div>
            </div>
            <div class="TabContentBox">
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 1">
                <div class="TabTextBox">
                  <p>
                    {{ Request.Descrizione | default-text}}
                  </p>
                </div>
              </div>
              <!-- Fine panello a scomparsa -->
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 2 ">
                <!-- Inizio tabella interna -->
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <th scope="col">Ticket Nr./Code</th>
                      <th scope="col">Date</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Request by</th>
                      <th scope="col">Status</th>
                      <th scope="col">Presumed closure date</th>
                      <th scope="col">Type</th>
                    </tr>
                    <tr v-for="Ticket in Tickets" :key="Ticket.Id">
                      <td scope="col">
                        <div class="TableLabel">Ticket Nr./Code</div>
                        {{Ticket.Id}}
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Date</div>
                        {{Ticket.Data | date}}
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Subject</div>
                        {{Ticket.Oggetto}}
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Request by</div>
                        {{Ticket.RichiestoDa}}
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Status</div>
                        {{Ticket.Status}}
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Presumed closure date</div>
                        {{Ticket.DataChiusura}}
                      </td>
                      <td scope="col">
                        <div class="TableLabel">Type</div>
                        {{Ticket.Tipo}}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Fine tabella interna -->
              </div>
              <!-- Fine panello a scomparsa -->
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 3">
                <div class="TabTextBox">
                  <p>
                      {{Request.NoteCS | default-text}}
                  </p>
                </div>
              </div>
              <!-- Fine panello a scomparsa -->
              <!-- Inizio panello a scomparsa -->
              <div class="Binder TabContent" v-if="activetab === 4">
                <div class="TabTextBox">
                  <p>
                    {{Request.NoteChiusura | default-text}}
                  </p>
                </div>
              </div>
              <!-- Fine panello a scomparsa -->
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import AxiosClient from '@/service/api.js'

export default {
  props: ['Request'],
   data() {
        return {
            showMore: false,
            activetab: 1,
            Tickets:[]
        }
   },
   methods: {
      getRelatedTickets() {
            AxiosClient
                .get("tickets?report=" + this.Request.Id)
                .then(response => (
                    this.Tickets = response.data.Data))
      },

     showMoreFn() {
      if  ( this.showMore ) {
        this.showMore = false
      } else {
        this.showMore = true
        this.getRelatedTickets()
      }
     }
   }
}
</script>
